import values from "./values";
import validation from "./validation";

const yoga = {
  selection:{
    defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
    question: "Please choose a yoga policy:"
  },
  teacher: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you offer a Yoga Teacher Training program?"
  },
  RYT500: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Are you RYT500 certified?"
  },
  studioyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Is the Yoga teacher program offered through a Studio?"
  },
  studioname: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Please provide us with the Studio name you certify\
    others from"
  },
  suspension: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you teach yoga that uses a harness or silks to suspend from\
    the ground(eg.Aerial yoga)?"
  },
  modo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Were you certified through or do you currently teach at a Modo Yoga Studio?"
  },
  yyoga: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Were you certified through or do you currently teach at a Yyoga Studio?"
  },
  power: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Were you certified through or do you currently teach at a Power Yoga Canada Studio?"
  },
  kidsyoga: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you teach Kids yoga?",
    info:
      "Parents/guardians must be outside the class or an adult must be in the class with instructor, \
      Signed waivers required, \
      Maximum 10 kids per class\
      Classes no longer than 60 minutes, \
      **These restrictions are waived if the class is being held in a school\
      "
  },
  ebookyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Have you written any books or e-books that were either\
    published or self published?",
    info:
      "Note: Downloadable books/e-books posted on your website, whether\
    for a cost or free should be declared."
  },
  ebookaddCoverage: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    info:
      "Publisher’s Liability coverage provides the following for an additional $75.00 annually If you have an incidental publishing, such as writing articles in published magazines or online, written a book that has been either published in paper or as an E-book, you should consider Publisher’s liability extension. Whether your e-book is free on your website or you sell them, your exposure is much higher and the individual policy does not cover published writings. Publisher’s liability gives you $25,000 in coverage should someone decide to sue you based on something you published. Please select to add to your policy.",
    question: "Do you wish to add Publisher’s Liability to your policy?"
  },
  paddleboardyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Does your instruction include Stand Up Paddleboard Yoga/fitness?"
  },
  paddleboardq1: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,

    question: "Are you currently certified in CPR? "
  },
  paddleboardq2: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you have a water safety or life saving society certificate?"
  },
  paddleboardq3: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "What is the maximum number of students allowed in one class?"
  },
  paddleboardq4: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Are introductory or first time lessons on shore?"
  },
  paddleboardq5: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Are life jackets worn by every student, including you?"
  },
  paddleboardq6: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do first time users where life jackets?"
  },
  paddleboardq7: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "If some students are not wearing a life jacket are the life jackets on the board or close by?"
  },
  paddleboardq8: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Are leashes used while students are on the boards?"
  },
  paddleboardq9: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Is the maximum depth of the water where lessons are held more than 10 feet deep?"
  },
  paddleboardq10: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "How far off shore do you practice?"
  },
  paddleboardq11: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you use board anchors?"
  },
  paddleboardq12: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Other details regarding Stand Up Paddleboard Yoga you would like to share:"
  },
  paddleboardaddCoverage: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you wish to add Stand Up Paddleboard coverage to your policy?",
    info:
      "To add coverage for Stand Up Paddleboard  to your policy, the additional premium will be $100.00 annually"
  },
  retreatyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you offer Yoga Retreats or events?",
    info:"Please fill out the following questions that the insurance company will need answered in order to consider extending coverage to your retreat or event. Please note Yoga Retreats and events are not automatically covered under this policy and an additional premium may apply."
  },
  retreat1: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "What is the location of the retreat/event?",
    info: "Please enter the full address"
  },
  retreat2: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "What is the start date of retreat/event?"
  },
  retreat3: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "What is the end date of retreat/event?"
  },
  retreat4: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Are you the facilitator and/or organizer?"
  },
  retreat5:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"If not, who is organizing this event?"
  },
  retreat6:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"Number of participants expected to attend:"
  },
  retreat7:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"Are all of the participants Canadian?"
  },

  retreat8:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"List the activities that will be offered at this event:"
  },
  retreat9:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"Of these activities, which are you responsible for facilitating?"
  },
  retreat10:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"How many instructors/practitioners will be attending"
  },
  retreat11:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"Do all instructors/practitioners attending have their own insurance?"
  },
  retreat12:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"Will the resort/retreat facility be providing and preparing all the food?"
  },
  retreat13:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"If not, who will be providing and preparing the food?"
  },
  retreat14:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"Will you be responsible for making the travel arrangements for the participants?"
  },
  retreat15:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"If not, who is responsible for the travel arrangements?"
  },
  retreat16:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"What first aid precautions will be in place?"
  },
  retreat17:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"How have you been advertising for this retreat/event?",
    info:"Please provide a link"
  },

  therapyyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are you certified as a Yoga Therapist and have completed 800\
    hours of training?"
  },
  therapyaddCoverage: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you wish to add Yoga Therapy coverage to your policy?",
    info:
      "To add coverage for Yoga Therapy to your policy, the additional premium will be $100.00 annually"
  },
  consultingyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you consult or offer programs with Clients via the internet?",
    info:
      "If you consult with clients via the internet (website, social\
      media), international Telephone call, Skype, or Face Time you\
      may purchase coverage for International Web Based Consulting"
  },
  consultingaddCoverage: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you wish to add International Web Based Consulting coverage to your policy?",
    info:
    "If you have an international exposure, where you are posting videos, blogs, have a website that contains\
    advise, or information, where you cannot control whether it’s a client within Canada or outside of\
    Canada, viewing your advice or content.\
    If you consult via Facebook, Zoom, Skype with clients outside of Canada.\
    Please consider adding the International Web Based Extension to your policy.\
    Please note this extension only allows 50% of your receipts to be from clients outside of Canada, Suites\
    must be brought back to Canada and waivers and a disclaimer must be provided on your website or to a\
    client prior to a session."
  },
  trx: {
      defaultValue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question: "Do you use TRX or Ropes in your practice?"
    },
    trxSuspension: {
      defaultValue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      info: "IMPORTANT EXCLUSION: Please note this policy prohibits full body suspension.  If you wish to proceed please confirm you do not allow clients to be fully suspended while using the ropes or TRX and you understand that in the event of an accident due to full body suspension, there is no coverage under this policy.",
      question: "Do you wish to proceed?"
    },
    consultingClientele:{
        defaultValue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        info: "",
        question: "Is your clientele more than 50% outside of Canada"
    },
    consultingCharge:{
        defaultValue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        info: "",
        question: "Add International coverage to your policy, for an additional $"
    },
    twoMillionAttestation:{
      defaultValue : values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Do you wish to continue?",
      visble: true
    }

};
export default yoga;
