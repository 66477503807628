import React from "react";
import Card from "components/Card";

import Typography from "@material-ui/core/Typography";
import {
  YesNoQuestion,
  TextQuestion,
  UploadQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

export default class Esthetics extends React.Component {
  render() {
    const { values, visible } = this.props;

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length - 1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].estheticsPrices;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].estheticsPrices;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Esthetics
        </Typography>
        <hr />

        <Typography variant={"h6"}>
          {" "}
          Please note the following modalites are automatically included under
          this Esthetics policy as long as they were part of your Certification
          program.{" "}
        </Typography>
        <ul>
          <li>Facials</li>
          <li>Pedicures</li>
          <li>Acne Treatments</li>
          <li>Microdermabrasion</li>
          <li>Superficial Chemical Peels </li>
          <li>Body Wraps, Masks, and Scrubs</li>
          <li>Waxing and Hair Removal</li>
          <li>Makeup Application</li>
          <li>Airbrush Tanning</li>
          <li>Manicures</li>
          <li>Light therapies</li>
          <li>Anti-aging treatments</li>
          <li>Skincare analysis</li>
          <li>Eyelash extensions</li>
        </ul>
        <br />
        <YesNoQuestion
          question={"Do you provide Hydrotherapy?"}
          id={"esthetics.hydrotherapy"}
          path={"esthetics.hydrotherapy"}
        />

        {values.esthetics.hydrotherapy === "yes" ? (
          <TextQuestion
            question={
              "Please provide a detailed description of the type of Hydrotherapy you offer."
            }
            id={"esthetics.hydrotherapyDescription"}
            path={"esthetics.hydrotherapyDescription"}
          />
        ) : null}
        <YesNoQuestion
          question={"Do you offer Laser Hair Removal "}
          id={"esthetics.laser"}
          path={"esthetics.laser"}
        />
         { values.esthetics.laser === "yes"
        ? (<YesNoQuestion
          id={"esthetics.coldOrLowLevelLasers"}
          path={"esthetics.coldOrLowLevelLasers"}
          question={
          "Is this a Cold lasers or low-level lasers machine?"
          }
        />)
        : null}
        { values.esthetics.coldOrLowLevelLasers === "no"
        ? (<YesNoQuestion
          id={"esthetics.ownLaserMachine"}
          path={"esthetics.ownLaserMachine"}
          question={
          "Do you own the Laser machine?"
          }
        />)
        : null}
        { values.esthetics.ownLaserMachine === "yes"
            ? (
            <>
            <TextQuestion
                id={"esthetics.laserMachineDetails"}
                path={"esthetics.laserMachineDetails"}
                question={
                "Please provide us with the Type of laser machine, make, model, S/N"
                }
            />
            <TextQuestion
                id={"esthetics.laserMachineAge"}
                path={"esthetics.laserMachineAge"}
                question={
                "Please provide us with the age of machine, where was it manufactured & value"
                }
            />
            <TextQuestion
                id={"esthetics.laserMachineMobile"}
                path={"esthetics.laserMachineMobile"}
                question={
                "Please advise if this laser equipment will be used in the clinic only or in different locations / mobile"
                }
            />
            <YesNoQuestion
                id={"esthetics.laserMachineWaivers"}
                path={"esthetics.laserMachineWaivers"}
                question={
                "Are waivers being signed by each participant?"
                }
            />
            <TextQuestion
                id={"esthetics.laserMachineMaintenance"}
                path={"esthetics.laserMachineMaintenance"}
                question={
                "What is the maintenance for this equipment?"
                }
            />
            <TextQuestion
                id={"esthetics.laserMachineLeasedOwned"}
                path={"esthetics.laserMachineLeasedOwned"}
                question={
                "Is the equipment leased or owned by you?"
                }
            />
            <TextQuestion
                id={"esthetics.laserMachineUse"}
                path={"esthetics.laserMachineUse"}
                question={
                "What is the machine used for?"
                }
            />
            <YesNoQuestion
                id={"esthetics.laserMachineUsedBy"}
                path={"esthetics.laserMachineUsedBy"}
                question={
                "Will there be other practitioners operating the equipment?"
                }
            />
            <YesNoQuestion
                id={"esthetics.laserMachineTraining"}
                path={"esthetics.laserMachineTraining"}
                question={
                "Are you properly trained for operations of this laser machine?"
                }
            />
            
            </>)
        : null}
        {values.esthetics.coldOrLowLevelLasers === "yes"
        ?  (<UploadQuestion
            question={"Please upload training certificate for the use of the machine"}
            fileCollectionName={"estheticsLaserMachineFiles"}
           />)
        :null}
         <YesNoQuestion
                id={"esthetics.microbladingYesOrNo"}
                path={"esthetics.microbladingYesOrNo"}
                question={
                "Do you offer Microblading under your aesthetics services?"
                }
            />
            {values.esthetics.microbladingYesOrNo === "yes"
            ?
            (<>
              <UploadQuestion
              question={"Please upload your certificate of training"}
              fileCollectionName={"microbladingCertificate"}
            />
              <YesNoQuestion
                id={"esthetics.microbladingAddnPremiumYesOrNo"}
                path={"esthetics.microbladingAddnPremiumYesOrNo"}
                question={
                "Would you like to add coverage for this for an additional premium of $76?"
                }
              />
          
            </>):null}

          <YesNoQuestion
                id={"esthetics.microneedlingYesOrNo"}
                path={"esthetics.microneedlingYesOrNo"}
                question={
                "Do you offer Microneedling under your aesthetics services?"
                }
            />
            {values.esthetics.microneedlingYesOrNo === "yes"
            ?
            (<>
              <UploadQuestion
              question={"Please upload your certificate of training"}
              fileCollectionName={"microneedlingCertificate"}
            />
            <YesNoQuestion
                  id={"esthetics.microneedlingAddnPremiumYesOrNo"}
                  path={"esthetics.microneedlingAddnPremiumYesOrNo"}
                  question={
                  "Would you like to add coverage for this for an additional premium of $151?"
                  }
            />
            </>):null}  
           
      </Card>
    );
  }
}
