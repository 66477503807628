import form from "constants/form";

const getTempSummary = (answers, visible) => {
  const ob = {};
  // console.log(answers, questions);

  Object.keys(answers).forEach(section => {
    ob[section] = {};
    Object.keys(form[section]).forEach(questionKey => {
      // console.log(section);

      if (answers[section][questionKey] === undefined) {
        throw new Error(section + " " + questionKey);
      }

      if (!visible[section][questionKey]) return;

      if (
        form[section][questionKey].info &&
        form[section][questionKey].info.length
      ) {
        ob[section][form[section][questionKey].question] = {
          Answer: answers[section][questionKey].toString(),
          "Note: ": form[section][questionKey].info
        };
      } else {
        ob[section][form[section][questionKey].question] = answers[section][
          questionKey
        ].toString();
      }
    });

    // Object.keys(answers[section]).forEach(questionKey => {
    //   if (form[section][questionKey] === undefined) {
    //     throw new Error(section + " " + questionKey);
    //   }
    // });
  });
  return ob;
};

function handlePrettyForm(values, visible) {
  var summary = getTempSummary(values, visible);

  let newOne = {
    applicantInformation: summary.applicantInformation,
    RMT: summary.RMT,
    yoga: summary.yoga,
    osteopathy: summary.osteopathy,
    NC: summary.NC,
    aromatherapy: summary.aromatherapy,
    personalTrainer: summary.personalTrainer,
    reflexology: summary.reflexology,
    kinesiology: summary.kinesiology,
    physiotherapy: summary.physiotherapy,
    fitnessInstructor: summary.fitnessInstructor,
    pilates: summary.pilates,
    crossfit: summary.crossfit,
    psychologist: summary.psychologist,
    acupuncture: summary.acupuncture,
    esthetics: summary.esthetics,
    lifeCoach: summary.lifeCoach,
    reiki: summary.reiki,
    sleepConsultant: summary.sleepConsultant,
    privacyBreach: summary.privacyBreach,
    disclaimers: summary.disclaimers,
    paymentOption: summary.paymentOption
  };

  summary = newOne;

  var quest = {
    applicantInformation: "Applicant Information",
    RMT: "Registered Massage Therapist",
    yoga: "Yoga",
    osteopathy: "Osteopathy",
    NC: "Nutritional Consultant",
    aromatherapy: "Aromatherapy",
    personalTrainer: "Personal Trainer",
    reflexology: "Reflexology",
    kinesiology: "Kinesiology",
    physiotherapy: "Physiotherapy",
    fitnessInstructor: "Fitness Instructor",
    pilates: "Pilates",
    crossfit: "Crossfit trainer",
    esthetics: "Esthetics",
    acupuncture: "Acupuncture/TCM",
    psychologist: "Psychologist",
    lifeCoach: "Life Coach",
    reiki: "Reiki",
    sleepConsultant: "Sleep Consultant",
    privacyBreach: "Privacy Breach/Cyber Security",

    disclaimers: "Disclaimers",
    paymentOption: "Payment option"
  };

  if (values.applicantInformation.modalities.indexOf("RMT") < 0) {
    delete summary.RMT;
    delete quest.RMT;
  }

  if (values.applicantInformation.modalities.indexOf("Yoga") < 0) {
    delete summary.yoga;
    delete quest.yoga;
  }

  if (values.applicantInformation.modalities.indexOf("Aromatherapy") < 0) {
    delete summary.aromatherapy;
    delete quest.aromatherapy;
  }

  if (values.applicantInformation.modalities.indexOf("NC") < 0) {
    delete summary.NC;
    delete quest.NC;
  }

  if (values.applicantInformation.modalities.indexOf("Osteopathy") < 0) {
    delete summary.osteopathy;
    delete quest.osteopathy;
  }

  if (values.applicantInformation.modalities.indexOf("Physiotherapy") < 0) {
    delete summary.physiotherapy;
    delete quest.physiotherapy;
  }

  if (
    values.applicantInformation.modalities.indexOf("Fitness Instructor") < 0
  ) {
    delete summary.fitnessInstructor;
    delete quest.fitnessInstructor;
  }

  if (values.applicantInformation.modalities.indexOf("Kinesiology") < 0) {
    delete summary.kinesiology;
    delete quest.kinesiology;
  }

  if (values.applicantInformation.modalities.indexOf("Reflexology") < 0) {
    delete summary.reflexology;
    delete quest.reflexology;
  }

  if (values.applicantInformation.modalities.indexOf("Personal Trainer") < 0) {
    delete summary.personalTrainer;
    delete quest.personalTrainer;
  }

  if (values.applicantInformation.modalities.indexOf("Acupuncture/TCM") < 0) {
    delete summary.acupuncture;
    delete quest.acupuncture;
  }

  if (values.applicantInformation.modalities.indexOf("Pilates") < 0) {
    delete summary.pilates;
    delete quest.pilates;
  }

  if (values.applicantInformation.modalities.indexOf("Crossfit trainer") < 0) {
    delete summary.crossfit;
    delete quest.crossfit;
  }

  if (values.applicantInformation.modalities.indexOf("Esthetics") < 0) {
    delete summary.esthetics;
    delete quest.esthetics;
  }

  if (values.applicantInformation.modalities.indexOf("Psychologist") < 0) {
    delete summary.psychologist;
    delete quest.psychologist;
  }
  if (values.applicantInformation.modalities.indexOf("Life Coach") < 0) {
    delete summary.lifeCoach;
    delete quest.lifeCoach;
  }
  if (values.applicantInformation.modalities.indexOf("Reiki") < 0) {
    delete summary.reiki;
    delete quest.reiki;
  }
  if (values.applicantInformation.modalities.indexOf("Sleep Consultant") < 0) {
    delete summary.sleepConsultant;
    delete quest.sleepConsultant;
  }

  if (values.privacyBreach.yesno === 0) {
      if(!values.applicantInformation.modalities.includes("Acupuncture/TCM")){
        delete summary.privacyBreach;
        delete quest.privacyBreach;
      }
  }

  var qs = Object.values(quest);
  var as = Object.values(summary);
  var res = {};

  qs.forEach((item, index) => {
    res[item] = typeof as === "object" ? as[index] : as;
  });

  summary = res;

  summary["Payment option"]["Payment Option"] = values.paymentOption.selection;
  summary["Payment option"]["Total"] = values.paymentOption.total;

  summary["Disclaimers"]["Notice Concerning Personal Information"] =
    "I hereby consent to BrokerLink to collect, use\
  and disclose personal information required for the purposes of\
  considering my application for insurance for new or renewal\
  insurance coverage. The Broker is authorized to collect, use and\
  disclose personal information and provide such personal\
  information to third parties, as required, including insurance\
  companies. The Broker may also be required to disclose such\
  personal information pursuant to relevant laws or other laws. I\
  authorize BrokerLink to communicate\
  directly with the member association.";

  summary["Disclaimers"]["Warranty Statement"] =
    "By submitting this application, you attest that the application\
    has been completed accurately and honestly. No disciplinary action\
    has been taken or is pending against you. You have never been the\
    subject of any investigation, either civil or criminal, in\
    connection with any sexual act, conduct, molestation, and/or\
    assault. You understand that your insurance certificate will\
    provide evidence that you have been added as an individual\
    participant with respect to the coverage and limits of the Master\
    Policy. You understand that the coverage provided by your\
    insurance certificate is subject to all the terms, conditions and\
    exclusions contained in the Master Policy. You further understand\
    that the Insurance Company will rely on the information you have\
    provided in the application. You are also able to provide a\
    certificate of training for the modality you practice on your\
    policy upon request. Failure to pay required premiums and/or false\
    statements on this application or subsequent renewals shall void\
    this application and render your insurance coverage null and void,\
    and you may be subject to further legal action if making false\
    statements.";


  return summary;
}
export default handlePrettyForm;
